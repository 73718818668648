
* {
  background-color: rgb(90, 130, 132);
  color: whitesmoke;
}

.App {
  text-align: center;
  background-color: rgb(90, 130, 132);
}

.logo {
  height: 150px;
  width: 200px;
}

* {
  box-sizing: border-box;
}

.projcard {
  float: left;
  width: 33.33%; 
  padding: 50px;
}
.navcss {
  background-color: rgb(90, 130, 132);
  font-size: 24px;
 
}
a:link {
  text-decoration: none;
}
a:link {
  color: whitesmoke;
}
a:visited {
  color: whitesmoke;
}
a:hover {
  color: rgb(255, 115, 0);
}
.proj {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;

}
button {
  background-color: rgb(90, 130, 132);
  color: whitesmoke;
  border: 2px solid whitesmoke;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  font-size: 16px;
}
.bio {
  width: 70%;
  margin: auto;
  font-size: 20px;
  text-align: left;
  
}
.headshot {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto;
  padding: 25px;
}

.linkedin {
  font-size: 25px;
}


